import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import logo from '../assets/aladdin_logo.png';
import Sidebar from './Sidebar';

const Header = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <header className="fixed left-0 w-full flex flex-row items-center justify-between px-8 sm:px-8 md:px-12 lg:px-20 py-8 z-10 bg-transparent font-roboto">
      <div className="flex items-center">
        {/* <Link to="/">
          <img src={logo} alt="Logo" className="h-8" />
        </Link> */}
      </div>
      <div className="ml-auto">
        <GiHamburgerMenu
          className="text-white cursor-pointer"
          size={32}
          onClick={toggleSidebar}
        />
      </div>
      <Sidebar isOpen={showSidebar} onClose={toggleSidebar} />
    </header>
  );
};

export default Header;
