import ceoImage from '../assets/profile/YoungJinSeo.png';
import ceoRecord1 from '../assets/profile/SeoYJ/1.jpg';
import ceoRecord2 from '../assets/profile/SeoYJ/2.jpg';
import ceoRecord3 from '../assets/profile/SeoYJ/3.png';
import ceoRecord4 from '../assets/profile/SeoYJ/4.png';
import cooImage from '../assets/profile/ParkTaeChan.png';
import cooRecord1 from '../assets/profile/ParkTaeChan/Picture1.jpg';
import cooRecord2 from '../assets/profile/ParkTaeChan/Picture2.png';
import cooRecord3 from '../assets/profile/ParkTaeChan/Picture3.jpg';
import cooRecord4 from '../assets/profile/ParkTaeChan/Picture4.png';
import richardDouglas from '../assets/profile/RichardDouglas.png';
import luqman from '../assets/profile/LuqmanAdeoye.png';
import cboImage from '../assets/profile/KyongHeeHan.png';
import cboRecord1 from '../assets/profile/Kyong Hee Han/1.png';
import cboRecord2 from '../assets/profile/Kyong Hee Han/2.png';
import csoImage from '../assets/profile/IanPark.png';
import cdoImage from '../assets/profile/ThomasKang.png';
import movieProductionImage from '../assets/profile/SungHoonKim.png';
import movieProductionRecord1 from '../assets/profile/SungHoonKim/1.png';
import movieProductionRecord2 from '../assets/profile/SungHoonKim/2.png';
import movieProductionRecord3 from '../assets/profile/SungHoonKim/3.png';
import tvProductionImage from '../assets/profile/JiYeonLee.png';
import tvProductionRecord1 from '../assets/profile/Ji Yeon Lee/1.png';
import tvProductionRecord2 from '../assets/profile/Ji Yeon Lee/2.png';
import tvProductionRecord3 from '../assets/profile/Ji Yeon Lee/3.png';
import tvProductionRecord4 from '../assets/profile/Ji Yeon Lee/4.jpg';
import tvProductionRecord5 from '../assets/profile/Ji Yeon Lee/5.png';
import tvProductionRecord6 from '../assets/profile/Ji Yeon Lee/6.png';
import tvProductionRecord7 from '../assets/profile/Ji Yeon Lee/7.png';
import tvProductionRecord8 from '../assets/profile/Ji Yeon Lee/8.png';
import tvProductionRecord9 from '../assets/profile/Ji Yeon Lee/9.png';
import tvProductionRecord10 from '../assets/profile/Ji Yeon Lee/10.png';
import tvProductionRecord11 from '../assets/profile/Ji Yeon Lee/11.png';
import tvProductionRecord12 from '../assets/profile/Ji Yeon Lee/12.png';
import tvProductionRecord13 from '../assets/profile/Ji Yeon Lee/13.png';
import tvProductionRecord14 from '../assets/profile/Ji Yeon Lee/14.png';
import tvProductionRecord15 from '../assets/profile/Ji Yeon Lee/15.jpg';
import cfoImage from '../assets/profile/SangHoonLee.png';
import ctoImage from '../assets/profile/JooWonKim.png';
import directorImage from '../assets/profile/ChoHanSang.png';
import pipelineImage from '../assets/profile/JooHanOh.png';
import pipelineRecord1 from '../assets/profile/Ju Han Oh/1.png';
import pipelineRecord2 from '../assets/profile/Ju Han Oh/2.png';
import moviePlanningImage from '../assets/profile/SeungCheolChen.png';
import moviePlanningRecord1 from '../assets/profile/Seung Cheol Chen/1.png';
import tvPlanningImage from '../assets/profile/HyounSinPark.png';
import productionManagement from '../assets/profile/SeiaPark.png';
import itAssetImage from '../assets/profile/DK Nam.png';
import jim from '../assets/profile/JimClemente.png';
import stanley from '../assets/profile/StanleyTong.png';
import jaehan from '../assets/profile/JaeHanLee.png';
import sungwon from '../assets/profile/SungWon Cho.png';
import peter from '../assets/profile/PeterClemente.png';
import taewoo from '../assets/profile/RaymondChung.png';
import raul from '../assets/profile/DrRaulSunico.png';
import jimRecord from '../assets/profile/JimClemente/1.png';
import stanleyRecord1 from '../assets/profile/StanleyTong/1.png';
import stanleyRecord2 from '../assets/profile/StanleyTong/2.png';
import jaehanRecord1 from '../assets/profile/JaeHanLee/1.png';
import jaehanRecord2 from '../assets/profile/JaeHanLee/2.png';
import jaehanRecord3 from '../assets/profile/JaeHanLee/3.png';
import sungwonRecord1 from '../assets/profile/SungWonZho/1.png';
import sungwonRecord2 from '../assets/profile/SungWonZho/2.png';
import sungwonRecord3 from '../assets/profile/SungWonZho/3.png';
import viceChairman from '../assets/profile/nico.png';
import chairman from '../assets/profile/wang.png';
const executives = [
  // {
  //   name: 'Charles, Tsung-Hsi, Wang',
  //   position: 'Chairman',
  //   description: '',
  //   image: chairman,
  //   record: {
  //     type: 'text',
  //     data: [
  //       'Chairman of "CHEZNICO Asset," offering funding for various government-related finances and humanitarian construction.',
  //       'CHEZNICO Asset\'s goal is to practice "Philanthropic Capitalism" by focusing on "Humanitarian Projects" that aim to enhance the quality of life in underdeveloped countries.',
  //       'Oversees the investment, operation, and estimation of a project that requires significant financial and resource allocation.',
  //       'Since 2010, Charles Wang has related with CHEZNICO Holdings Inc. as strategic partner, and co-operated following events: The Korean-Chinese joint venture movie “Three Kingdoms” Musical “B Boys & Girl” and "Hamlet" German rock band concert "Helloween & Gammaray"',
  //       'In April 2012, proposed a 20 billion Baht investment in the Phuket Light Rail project to Mr. Tri Akkaradecha, the Governor of Phuket.v',
  //       'Engaged in diverse collaborative development projects including financial instruments such government bonds, collateral bonds, special trading, and asset management, creating an Economy Invigoration Fund for the US, China, Japan, and Southeast Asia.',
  //     ].map((txt) => ({ type: 'text', content: txt })),
  //   },
  // },
  // {
  //   name: 'Nico, Min Sung, Shin',
  //   position: 'Vice Chairman',
  //   description: '',
  //   image: viceChairman,
  //   record: {
  //     type: 'text',
  //     data: [
  //       '- Vice Chairman Shin, Min Sung graduated from Blackheath Art Foundation in the UK and L.C.F in London.',
  //       '- Worked as a general marketing director at Bosung Apparel Co., Ltd., and Seongdo Co., Ltd.',
  //       '- Served as CEO of the financial company CUPS for the Chinese market, and also the representative of the satellite broadcasting business group.',
  //       '- Senior Advisor for the Korea Brand Association (KBA) 11 years, and has been preparing to open branches in other countries.',
  //       '- Founded Cheznico holdings Inc. in 2006 as a cultural business expert, and accumulated a lot of experiences in investment, marketing, and public relations. Starting from an investment in the F&B business: "A.O.C" French restaurant, also participated in a member of fusion food business in Seoul Korea.',
  //       '- From 2010, Nico has a Business Relationship with Charls Wang as a partner, and then they have invested, planned and organized all the following events:',
  //       '   •	 The Korean-Chinese joint venture movie "Three Kingdoms"',
  //       '   •	 Musical "B Boys & Girl" and "Hamlet"',
  //       '   •	 German rock band concert "Helloween & Gammaray"',
  //       '- Participated with Film Producing Team in marketing and investment in many Korean movies that launched successfully within the Korean film market.',
  //       '   •	 "Fighting Spirit" (released Sep. 2011)',
  //       '   •	 "100 Percent" (released Apr. 2014)',
  //       '   •	 "The gift of the summer" (released Oct.2020)',
  //       '- Continuously developed the Korean Wave Culture business by managing performances at the Beverly Hills Four Seasons Hotel in the U.S.A. in 2012.',
  //       '- These cultural business performances attracted many foreign investors, and accumulated various experiences with world class investors.',
  //       '- Participated in various co-working development projects, using various financial instruments such as issuing government bonds, collateral bonds, and special trading, asset management.',
  //       '- After scouting Architecture Design Team, participated in several Real Estate Development Implementation and designing.',
  //       '   •	 Fishing Village-Port Commercial Space (2015)',
  //       '   •	 Seongbuk Hills-Landscape Architecture Design (2017)',
  //       '- International financing consulting activities is a primary interest for us since we have valuable contacts and information in the following countries: China, Southeast Asia, Congo, Guinea, Zimbabwe, Venezuela and the United States.',
  //       '- Nico Shin has received an award certificate from Korean National Assembly for the reason of developing international private interchange and informing Korea to the international community.',
  //     ].map((txt) => ({ type: 'text', content: txt })),
  //   },
  // },
  {
    name: 'Young Jin Seo',
    position: 'President / CEO',
    description:
      'Mr. Seo has had an illustrious career as an executive of global talent agencies and movie production studios in the US, China, and Korea. He was also the first to bring together Paradigm Talent Agency and China Film Group, the largest film enterprise in China',
    image: ceoImage,
    record: {
      type: 'image',
      data: [ceoRecord1, ceoRecord2, ceoRecord3, ceoRecord4].map((img) => ({
        type: 'image',
        content: img,
      })),
    },
  },
  {
    name: 'Tae Chan Park',
    position: 'COO',
    description:
      'Mr. Park graduated as a Directing & Photography major, and left numerous achievements as a commercial advertising director. Since then, his career flourished in filmmaking and studio business.',
    image: cooImage,
    record: {
      type: 'image',
      data: [cooRecord1, cooRecord2, cooRecord3, cooRecord4].map((img) => ({
        type: 'image',
        content: img,
      })),
    },
  },
  {
    name: 'Richard Douglas',
    position: 'Director of Global Contents',
    description:
      'A seasoned professional with a multifaceted career spanning various sectors and continents. With a foundation forged during his tenures in the Finance and Entertainment Industries, Mr. Douglas seamlessly integrated cryptocurrency expertise into his repertoire during his time in Korea. This expansion aligns perfectly with the current landscape, positioning them as a visionary in the intersection of finance, entertainment, and emerging technologies.',
    image: richardDouglas,
    record: {
      type: 'text',
      data: [
        '1999-2004: Subsidiary Investment Manager at Pacific Guarantee Bank, Royal Bank of Canada',
        '2004-2007: Mortgage Investment Manager in the United States',
        '2008-2010: CEO of Concord Entertainment, a China-based and US-based entertainment company',
        '2009-2011: Entertainment consultant for a paradigm-shifting Chinese film group, raising funds and overseeing the production of joint film projects',
        '2012-2018: Lumenas Studios – Asia Entertainment Industry Consultant / Vice President of Global Strategy. Established strategic partnerships with Chinese and Korean companies for funding and production of animated and live-action films.',
        '2018-Present: Vice President of Global Strategy at SLG USA',
      ].map((txt) => ({ type: 'text', content: txt })),
    },
  },
  {
    name: 'Luqman A. Adeoye',
    position: 'Executive Director',
    description: '',
    image: luqman,
    record: {
      type: 'text',
      data: [
        'As a business consultant with over 20 years of experience as a top business owner, Mr. Adeoye has collaborated with high-tech companies, international organizations, and inventors, developing procedural documents. His expertise lies in establishing reliable partnerships in GCC countries (Saudi Arabia, UAE, Qatar, Bahrain, Kuwait, Oman) and the African region, providing direct access opportunities to international organizations and businesses. A negotiation expert with a focus on removing unnecessary bottlenecks in the process of bringing together like-minded individuals for the common benefit, fostering unity and collaboration.',
      ].map((txt) => ({ type: 'text', content: txt })),
    },
  },
];

const production = [
  {
    name: 'Kyong Hee Han',
    position: 'Director of Marketing / CBO',
    description: '',
    image: cboImage,
    record: {
      type: 'text',
      data: [
        '-28yrs of brand & marketing experience in both top brand and agencies, Specialized  in Brand strategy, Marcomm. Digital, PPL & Partnership,',
        '-Key achievement in Samsung; Brand strategy & governance, VI Mgmt., Global  Landmark OOH project (NY/Piccadilly/Hong Kong), Global AOR pitch, Brand videos,  Olympic/Football marketing, Samsung.com Re-new, Social /content strategy,  PPL(Product placement) & Global Partnership (2012~ ): Jurassic World,  OTT(House Of Cards), Music Video(Ariana Grande)',
        '-Main lead in 2012 London Olympic marketing and on-site activation',
        '-Top account mgmt. in agency : Cartier, Coca Cola, HP, P&G, Fidelity',
        '-Pioneered Fashion, Luxury brand marketing; CARTIER, Guess, Ralph Lauren',
        '-SAMSUNG ELECTROINCS, GLOBAL MARKETING OFFICE MARKETING DIRECTOR',
        '-HEAD OF BRAND STRATEGY & MANAGEMENT (2018 Dec– present)',
        '-Mid & Long-term brand Strategy, governance, equity build-up',
        '-Brand Architecture, VI Playbook(ATL/BTL, package, product, retail..)',
        '-Launch VI Review/Training System, Creative development process',
        '-Brand embodiment, Trademark/IP/Endorsed brand management',
        '-HEAD OF SOCIAL, MEDIA, PPL (2017 Nov – 2018 Dec)',
        '-Global Social Strategy, Channel architecture enhancement',
        '-Global Social Content Playbook, Global content creation, Branded film-Editor in Chief Facebook/Youtube global channel operation',
        '-Global annual media strategy and playbook, AOR management',
        '-Millennial PJT: Brand Showcase Digitization- Family Guy partnership',
        '-Global media activation: Landmark OOH, Global media partnership',
        '-Global Product Placement-Blockbuster film/OTT, Movie partnership',
        '-HEAD OF CONTENT STRATEGY, PPL (2015 Mar – 2017 Nov)',
        '-Digital content strategy, Social media marketing/governance',
        '-Global PPL program; Jurassic World partnership marketing, Product integration in  House of Cards, MV PPL in global artists',
        '-Ariana Grande, Britney Spears. Paik Nam June Show',
      ].map((txt) => ({ type: 'text', content: txt })),
    },
  },
  {
    name: 'Ian Park',
    position: 'CSO',
    description: '',
    image: csoImage,
    record: {
      type: 'text',
      data: [
        '-University of Missouri Columbia / Baruch College – Dropped, SCU / Master’s in Multimedia',
        '-1999 Founder of Nabi Interactive / Partner at SBS, involved in CG, gaming, and commercial film production / Contributed to numerous animation productions for Disney, Warner Bros., and others.',
        '-2002 Developed at 3DPaper (Had product development contracts with Bandai, Takara Tomy, Hasbro for 6 years)',
        '-2007 CEO of Interkosa / Partnered with Japan’s Sega, C-Dic / Developed Indoor urban theme parks',
        '-2012 CEO of Crowd Asset Co., Ltd. / Investments',
        '-2016 CEO of CSCO Holdings Co., Ltd. / Business Consulting',
        '-2022 to present – CEO of Louis ENM / Entertainment, CEO of PIG Club / Premium Investment Group',
      ].map((txt) => ({ type: 'text', content: txt })),
    },
  },

  {
    name: 'Thomas Kang',
    position: 'CDO',
    description: '',
    image: cdoImage,
    record: {
      type: 'text',
      data: [
        '- 2014 IE Business School – Master in Management',
        '- 2015 Alpha Founders (Bangkok, Thailand) – BD and Digital Marketing Manager',
        '- 2015 Rocket Internet (Singapore/Malaysia) – Founding member of a carpooling platform',
        '- 2016 Applr (South Korea/Singapore) – Head of BD & Market expansion team of the world’s first video commerce platform',
        '- 2017 Grezzy (Singapore/Taiwan) – Co-founder/COO of IOT startup',
        '- 2020 to present – TrillionsLab – CEO and Founder',
        '- Partnered with BlockApps, the leading US-based BaaS (Blockchain as a Service) provider in the market',
      ].map((txt) => ({ type: 'text', content: txt })),
    },
  },
  {
    name: 'Sung Hoon Kim',
    position: 'Director of Movie Productions',
    description: '',
    image: movieProductionImage,
    record: {
      type: 'mixed',
      data: [
        {
          type: 'text',
          content: '-2004 ~ 2010 CINEMA SERVICE Film Producers',
        },
        {
          type: 'text',
          content:
            '-2010 ~ 2014 Cinus Entertainment Distribution Team Leader (Megabox Plus M)',
        },
        {
          type: 'text',
          content:
            '-2014 ~ 2016 Director of Golden Tide Pictures Production Business',
        },
        {
          type: 'text',
          content: '-2017 ~2022 Woolim Film Producers',
        },
        {
          type: 'image',
          content: movieProductionRecord1,
        },
        {
          type: 'image',
          content: movieProductionRecord2,
        },
        {
          type: 'image',
          content: movieProductionRecord3,
        },
      ],
    },
  },
  {
    name: 'Ji Yeon Lee',
    position: 'Director of TV-Series Productions.',
    description: '',
    image: tvProductionImage,
    record: {
      type: 'mixed',
      data: [
        {
          type: 'text',
          content:
            '-From 2003 to 2005, she was a documentary director at MBC Productions',
        },
        {
          type: 'text',
          content:
            '-In 2005, she served as the executive director of the joint program ‘Mountain Bun’ between Shanghai Eastern Satellite TV and MBC in Korea',
        },
        {
          type: 'text',
          content:
            '-While working as a professional director of broadcasting programs, she has been actively directing and directing Korean K-pop and K-Drama performances in China since 2009',
        },
        {
          type: 'text',
          content:
            '-Since 2015, she has worked as a director of content and management in Korea with director Dang Gye-rye at China Film International in China',
        },
        {
          type: 'image',
          content: tvProductionRecord1,
        },
        {
          type: 'image',
          content: tvProductionRecord2,
        },
        {
          type: 'image',
          content: tvProductionRecord3,
        },
        {
          type: 'image',
          content: tvProductionRecord4,
        },
        {
          type: 'image',
          content: tvProductionRecord5,
        },
        {
          type: 'image',
          content: tvProductionRecord6,
        },
        {
          type: 'image',
          content: tvProductionRecord7,
        },
        {
          type: 'image',
          content: tvProductionRecord8,
        },
        {
          type: 'image',
          content: tvProductionRecord9,
        },
        {
          type: 'image',
          content: tvProductionRecord10,
        },
        {
          type: 'image',
          content: tvProductionRecord11,
        },
        {
          type: 'image',
          content: tvProductionRecord12,
        },
        {
          type: 'image',
          content: tvProductionRecord13,
        },
        {
          type: 'image',
          content: tvProductionRecord14,
        },
        {
          type: 'image',
          content: tvProductionRecord15,
        },
      ],
    },
  },
  {
    name: 'Sang Hoon Lee',
    position: 'CFO (KICPA)',
    description: '',
    image: cfoImage,
    record: {
      type: 'text',
      data: [
        '-Korea Certified Public Accountant (KICPA)',
        '-Former public institution management evaluation committee member',
        '-Former member of Anjin Accounting Firm Startup Advisory Group-Conducting accounting audits for private and public companies',
        '-Process design and verification related to settlement of accounts and preparation of financial statements',
        '-Internal accounting management system design and evaluation',
        '-Establishment of budget plan and financial strategy through preparation of estimated financial statements',
        '-Information calculation process design and management system establishment to provide managerial accounting information to CEO and CFO',
        '-Due diligence and valuation of the acquisition target company',
        '-Strategy establishment and business feasibility assessment for new business',
        '-KPI establishment, goal setting, and management for each division reflecting the results of company-wide strategy establishment',
      ].map((txt) => ({ type: 'text', content: txt })),
    },
  },
  {
    name: 'Joowon Kim',
    position: 'CTO',
    description: '',
    image: ctoImage,
    record: {
      type: 'text',
      data: [
        '-2019 University of Minnesota – Twin Cities, Computer Science',
        '-2020 Apolis Research and Development Team',
        '-2021 DeltaDental, Java Backend Developer',
        '-2021 to present – Trillionslab Co., Ltd., Blockchain Consultant and Engineer',
        '-Managing local partnerships with BlockApps.',
      ].map((txt) => ({ type: 'text', content: txt })),
    },
  },
  {
    name: 'Cho Han Sang',
    position: 'Director',
    description: '',
    image: directorImage,
    record: {
      type: 'mixed',
      data: [
        {
          type: 'text',
          content: '-Hongik University / Visual Communication Design',
        },
        {
          type: 'text',
          content: '-L.I.M Digital Production –Chief Art Director',
        },
        {
          type: 'text',
          content:
            '-Cosmopolitan Film Production New York, U.S.A. – Production Producer',
        },
        {
          type: 'text',
          content: '-VFX Studio VIXEN – Executive Producer & VFX Supervisor',
        },
        {
          type: 'text',
          content: '-VIXEN Production – Director & Producer',
        },
      ],
    },
  },
];

const operation = [
  {
    name: 'Joo Han Oh',
    position: 'Pipeline Engineer Supervisor',
    description: '',
    image: pipelineImage,
    record: {
      type: 'mixed',
      data: [
        {
          type: 'text',
          content: '-Red Rover, CG Supervisor',
        },
        {
          type: 'text',
          content: '-AzWorks, CG Supervisor',
        },
        {
          type: 'text',
          content: '-Macrograph, Pipeline TD',
        },
        {
          type: 'text',
          content: '-Kinomotive, Pipeline Supervisor',
        },
        {
          type: 'image',
          content: pipelineRecord1,
        },
        {
          type: 'image',
          content: pipelineRecord2,
        },
      ],
    },
  },
  {
    name: 'Seung Cheol Chen',
    position: 'Direcetor of Movie Planning',
    description:
      'Bringing years of experience and a proven track record in the industry, Director Chen has not only shaped compelling narratives but has also masterfully navigated the complexities of movie planning. Their dedication to perfection, combined with a keen understanding of audience preferences, ensures that every project not only meets but exceeds expectations.',
    image: moviePlanningImage,
    record: {
      type: 'mixed',
      data: [
        {
          type: 'text',
          content: '-(1998 – 2018) Sidus HQ Production Management Team Leader',
        },
        {
          type: 'text',
          content: '-(2019 – Present) CEO of Woollim Film Company',
        },
        {
          type: 'text',
          content:
            '-(2019 – Present) Professor, Department of Film, Cheongju University',
        },
        {
          type: 'text',
          content:
            '-(2019 – Present) Professor, Seoul National University of Arts, Department of Cinematic Arts Adjunct School',
        },
        {
          type: 'image',
          content: moviePlanningRecord1,
        },
      ],
    },
  },
  {
    name: 'Hyoun Sin Park',
    position: 'Director of TV Planning',
    description: '',
    image: tvPlanningImage,
    record: {
      type: 'mixed',
      data: [
        {
          type: 'text',
          content: '-2018 The spy Gone North (K), VFX producer',
        },
        {
          type: 'text',
          content: '-2018 Little Forest (K), VFX producer',
        },
        {
          type: 'text',
          content: '-2018 Heung-boo (K), VFX producer',
        },
        {
          type: 'text',
          content: '-2017 Bleeding Steel (C), VFX producer',
        },
        {
          type: 'text',
          content: '-2017 Memoir of a Murderer (K), VFX producer',
        },
        {
          type: 'text',
          content: '-2017 RESET (C), VFX producer',
        },
        {
          type: 'text',
          content: '-2016 Missing(K), VFX producer',
        },
        {
          type: 'text',
          content: '-2016 The Last Race (c), VFX producer',
        },
        {
          type: 'text',
          content: '-2016 Proof of Innocence (K), VFX producer',
        },
        {
          type: 'image',
          content: cboRecord1,
        },
        {
          type: 'image',
          content: cboRecord2,
        },
      ],
    },
  },
  {
    name: 'Seia Park',
    position: 'Production Management',
    description: '',
    image: productionManagement,
    record: {
      type: 'text',
      data: [
        '-Born in Seoul in 1973, Applied Arts Major, Induk University of Arts',
        '-SyDProduction TV-CM Assistant Director 1994',
        '-2000Alphavill 44Production TV-CM Assistant Director 2000-2005',
        '-Uranium238Production  Executive Producer 2005-2020',
        '-Vixen STUDIO Executive Producer 2021-2023',
        '-Excellence Award KOREA Advertising Award',
        '-TV advertising SAMSUNG I LG l SK l HYUNDAI MOTORS l KIA MOTORS l CJ l KOREAN AIR l ASIANA AIRLIES l NAVER l McDONALDS l EIDER l MAXIM COFFEE l Brand FILM SAMSUNG GLOBAL Campaign l DOOSAN CES FILM l HYUNDAI CES FILM l HANA BANK Brand FILM l SK TELECOM l CJ GLOBAL Campaign',
        '-Over the past 30 years, more than 1,000 commercials and brand films have been produced',
      ].map((txt) => ({ type: 'text', content: txt })),
    },
  },
  {
    name: 'D.K Nam',
    position: 'IT Asset Manager',
    description: '',
    image: itAssetImage,
    record: {
      type: 'text',
      data: [
        '        -Python Certification, PCEP / PCAP',
        '-Rudimentary knowledge of Typescript / R',
        '-Thorough understanding of Linux-based systems and Command Line.',
        '-Full Stack Development capabilities, geared towards React-based builds (HTML, CSS, Javascript)',
        '-Has worked with Unity and Unreal Engine',
        '-Familiar with photo-editing and video-editing',
        '-Capable and knowledgeable on data mining, cleaning, and production of statistical analyses',
      ].map((txt) => ({ type: 'text', content: txt })),
    },
  },
];

const advisor = [
  {
    name: 'Jim Clementle',
    position: 'Author  Former FBI Profiler',
    description:
      'James “Jim” T. Clemente (born October 30, 1959, San Mateo, California) is an American author, former New York State prosecutor, former FBI profiler, podcast co-host and creator of the show Real Crime Profile, and writer/producer on CBS’ Criminal Minds.',
    image: jim,
    record: {
      type: 'image',
      data: [jimRecord].map((img) => ({ type: 'image', content: img })),
    },
  },
  {
    name: 'Stanley Tong',
    position: 'Movie Director',
    description:
      'Stanley Tong (Chinese: 唐季禮) is a Hong Kong film director, producer, stunt choreographer, screenwriter, entrepreneur and philanthropist.',
    image: stanley,
    record: {
      type: 'image',
      data: [stanleyRecord1, stanleyRecord2].map((img) => ({
        type: 'image',
        content: img,
      })),
    },
  },
  {
    name: 'Jae Han Lee',
    position: 'Movie Director',
    description:
      'Lee Jae-han (born 1971), also known as John H. Lee, is a South Korean film director and screenwriter. Lee studied filmmaking at New York University. Although making films of different genres each time, his directing talent, chic and sensuous, runs through all his works. Since his debut in 2000, he has made several box office successes such as A Moment to Remember (2004), Sayonara Itsuka (2010), 71: Into the Fire (2010) and Operation Chromite (2016).',
    image: jaehan,
    record: {
      type: 'image',
      data: [jaehanRecord1, jaehanRecord2, jaehanRecord3].map((img) => ({
        type: 'image',
        content: img,
      })),
    },
  },
  {
    name: 'Sung Won Zho',
    position: 'Art Director  Professor',
    description:
      'Mr. Zho is the representative of Korea’s No. 1 film and drama art company, and is also an art director, film director, and university professor.',
    image: sungwon,
    record: {
      type: 'image',
      data: [sungwonRecord1, sungwonRecord2, sungwonRecord3].map((img) => ({
        type: 'image',
        content: img,
      })),
    },
  },
  {
    name: 'Peter Clemente',
    position: 'Fordham University / New York, NY',
    description:
      'For consultation in media and entertainment, marketing, data analysis, digital media, business development, corporate development, strategic planning, operations, business modeling, fundraising, and consensus building.',
    image: peter,
    record: {
      type: 'text',
      data: [
        '-Co-CEO/CMO of Guardians, Inc., a media and entertainment asset of Stan Lee.',
        '-CEO of XG Productions, Inc., a media production company.',
        '-Founder/Director of Domani Ventures, a venture fund/corporate development company.',
        '-Senior Vice President of Relationship Marketing at SONY Pictures Entertainment’s global film studio.',
        '-Vice President of Direct Marketing and CRM at SONY Music International’s global music company in New York.',
        '-Senior Analyst for publishing and analysis of Emerging Technologies at IDC/Fulcrum Analytics in New York.',
      ].map((txt) => ({ type: 'text', content: txt })),
    },
  },
  {
    name: 'TaeWoo Raymond Chung',
    position: 'University of California, BERKELEY',
    description: '',
    image: taewoo,
    record: {
      type: 'text',
      data: [
        '-2017-Present: Managing Partner at GOLDEN EQUATOR GROUP',
        '-2016: Executive Director at Powergene Assets Singapore',
        '-2007: Deputy General Manager, Asset Finance Division at Nomura Group',
        '-2010: Director at Nomura Real Estate Asset Management Co., Ltd., Seoul, South Korea',
        '-2007: Deputy General Manager, Real Estate Investment Management Division at Nomura Securities, Tokyo, Japan',
        '-2006: Manager, Corporate Finance, IBD at BARCLAYS CAPITAL PLC, Singapore',
        '-2004: Credit Rating Specialist at STANDARD & POOR’S INTERNATIONAL LLC',
        '-2001: Executive in Charge of Accounting at ING BARINGS SECURITIES LIMITED, Tokyo',
        '-2000: Bond Analyst at Deutsche Bank Group, Tokyo',
      ].map((txt) => ({ type: 'text', content: txt })),
    },
  },
  {
    name: 'Dr. Raul M Sunico',
    position: 'Pianist',
    description: '',
    image: raul,
    record: {
      type: 'text',
      data: [
        'As a world-renowned pianist with a dazzling array of awards, he has left an indelible mark on the music scene in the Philippines. From 2010 to 2017, he served as the chairman of the Cultural Center of the Philippines (CCP) and the dean of the University of Santo Tomas Conservatory of Music. Currently, he serves as a member of the National Commission for Culture and the Arts and as the president of the Classical Music Foundation.',
      ].map((txt) => ({ type: 'text', content: txt })),
    },
  },
];

export { executives, production, operation, advisor };
