import React, { useState, useEffect } from 'react';
import { LuChevronDownCircle } from 'react-icons/lu';
import { useScrollSections, ScrollingDots } from '../components/ScrollingDots';
import {
  executives,
  production,
  operation,
  advisor,
} from '../components/TeamMembers';
import MemberCard from '../components/MemberCard';
import MemberModal from '../components/MemberModal';

const OurTeam = () => {
  const numSections = 4;
  const { snapContainerRef, currentSection, scrollToSection } =
    useScrollSections(numSections);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const openModal = (member) => {
    setSelectedMember(member);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMember(null);
  };

  useEffect(() => {
    // Simulate fetching data
    const fetchData = async () => {
      try {
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const getGridClasses = (maxPerRow) => {
    switch (maxPerRow) {
      case 4:
        return 'grid-cols-4 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 mobile-landscape:grid-cols-4';
      case 8:
        return 'grid-cols-4 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 mobile-landscape:grid-cols-4';
      case 5:
        return 'grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 mobile-landscape:grid-cols-4';
      default:
        return 'grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 mobile-landscape:grid-cols-4';
    }
  };

  const renderSectionMembers = (members, maxPerRow, sectionNumber) => {
    // if (sectionNumber === 0) {
    //   // Special layout for the first section
    //   const [firstMember, ...restMembers] = members;
    //   return (
    //     <>
    //       <div className="flex flex-col items-center mb-8">
    //         <MemberCard key={firstMember.id} member={firstMember} onClick={() => openModal(firstMember)} />
    //       </div>
    //       <div className={`grid ${getGridClasses(maxPerRow)} gap-8 w-full lg:px-4`}>
    //         {restMembers.slice(0, maxPerRow).map((member) => (
    //           <MemberCard key={member.id} member={member} onClick={() => openModal(member)} />
    //         ))}
    //       </div>
    //     </>
    //   );
    // }

    // Default layout for other sections
    return members
      .slice(0, maxPerRow)
      .map((member) => (
        <MemberCard
          key={member.id}
          member={member}
          onClick={() => openModal(member)}
        />
      ));
  };

  return (
    <div className="relative">
      {error && <p>Error: {error}</p>}
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className="bg-[#1c224d] snap-container" ref={snapContainerRef}>
            {/* Section 1 */}
            <section className="snap-section h-screen flex flex-col justify-center items-center p-8 fade-in mobile-landscape:pt-16">
              <div className={`grid ${getGridClasses(4)} gap-8 w-full lg:px-4`}>
                {renderSectionMembers(executives, 4, 0)}
              </div>
              {currentSection === 0 && (
                <LuChevronDownCircle
                  className="absolute bottom-5 left-1/2 transform -translate-x-1/2 text-white text-4xl"
                  aria-label="Scroll down"
                />
              )}
            </section>

            {/* Section 2 */}
            <section className="snap-section h-screen flex flex-col justify-center items-center p-8 fade-in mobile-landscape:pt-16">
              <div className={`grid ${getGridClasses(8)} gap-8 w-full lg:px-4`}>
                {renderSectionMembers(production, 8, 1)}
              </div>
              {currentSection === 1 && (
                <LuChevronDownCircle
                  className="absolute bottom-5 left-1/2 transform -translate-x-1/2 text-white text-4xl"
                  aria-label="Scroll down"
                />
              )}
            </section>

            {/* Section 3 */}
            <section className="snap-section h-screen flex flex-col justify-center items-center p-8 fade-in mobile-landscape:pt-16">
              <div className={`grid ${getGridClasses(5)} gap-8 w-full lg:px-4`}>
                {renderSectionMembers(operation, 5, 2)}
              </div>
              {currentSection === 2 && (
                <LuChevronDownCircle
                  className="absolute bottom-5 left-1/2 transform -translate-x-1/2 text-white text-4xl"
                  aria-label="Scroll down"
                />
              )}
            </section>

            {/* Section 4 */}
            <section className="snap-section h-screen flex flex-col justify-center items-center p-8 fade-in mobile-landscape:pt-16">
              <div className={`grid ${getGridClasses(8)} gap-8 w-full lg:px-4`}>
                {renderSectionMembers(advisor, 8, 3)}
              </div>
            </section>

            {currentSection === 3 && (
              <button
                onClick={() => scrollToSection(0)}
                className="fixed bottom-5 right-5 bg-white text-black p-2 rounded-full shadow-lg"
                aria-label="Back to Top"
              >
                Back to Top
              </button>
            )}
          </div>
          <ScrollingDots
            currentSection={currentSection}
            sections={Array(numSections).fill(null)}
            scrollToSection={scrollToSection}
          />
          {selectedMember && (
            <MemberModal
              isOpen={isModalOpen}
              onClose={closeModal}
              member={selectedMember}
            />
          )}
        </>
      )}
    </div>
  );
};

export default OurTeam;
